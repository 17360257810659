import styled from "styled-components";

import { ShopTabsMenuItemStyledProps as Props } from "./ShopTabsMenuItem.types";

const ShopTabsMenuItemStyled = styled.div<Props>`
  & path {
    fill: var(--palette-gray-s0-l50);
  }

  && .TabsMenuItem__title {
    color: var(--palette-gray-s0-l50);
  }

  .ShopTabsMenuItem {
  }
`;

export default ShopTabsMenuItemStyled;
